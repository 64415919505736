import { MobileService } from './modules/shared/services/mobile.service';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as dayjs from 'dayjs';
import 'dayjs/locale/it';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as duration from 'dayjs/plugin/duration';
import {Observable, Subscription} from 'rxjs';
import { CmsService } from './modules/cms/services/cms.service';
import { MetaService } from './modules/shared/services/meta.service';
import { PostMessageService } from './modules/shared/services/post-message.service';
import { WebsiteConfigService } from './modules/shared/services/website-config.service';
import { WidgetService } from './modules/shared/services/widget.service';
import stringToElement from './utils/string-to-element';
import {Networks, NetworkConfigs } from './modules/shared/models/networks.enum';
import {ProjectService} from './modules/shared/services/project.service';
import {PrerenderService} from "./services/prerender.service";
import {ScriptLoaderService} from "./services/script-loader.service";
import {NavigationEnd, Router} from '@angular/router';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;
  public isLanding: boolean;
  public isStoreLocatorWidget: boolean;
  public isQrPage: boolean;
  public menuOpen: boolean;
  private toggleMenuNavSub: Subscription;
  private cookieCheckSubscription: Subscription;

  isStoreLocatorWidget$: Observable<boolean>;
  isWidget$: Observable<boolean>;
  isB2B$: Observable<boolean>;
  isFlashViaggiConventionPage: boolean;
  isWTG: boolean;
  isStoreLocatorWidgetSubscription: Subscription;
  customJsHtmlSubscription: Subscription;
  fbPixelSubscription: Subscription;
  isWidgetSubscription: Subscription;
  cmsEnabledSub: Subscription;
  isPrerender: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,
              private _websiteConfigService: WebsiteConfigService,
              private _widgetService: WidgetService,
              private _postMessageService: PostMessageService,
              private _metaService: MetaService,
              private _cmsService: CmsService,
              private _router: Router,
              private _projectService: ProjectService,
              private _mobileService: MobileService,
              private _prerenderService: PrerenderService,
              private _scriptLoaderService: ScriptLoaderService
  ) {
     this._router.events.subscribe((e) => {
       if (e instanceof NavigationEnd) {
         this.isFlashViaggiConventionPage = e.url.includes('convenzioni.html');
       }
     });
     this._metaService.removeIndexingFromGoogle();
    this.toggleMenuNavSub = this._mobileService.toggleSideBar$.subscribe( toggle => {
      this.menuOpen = toggle;
    })

    this.isPrerender = this._prerenderService.isPrerender;
    if(!this.isPrerender){
      this._scriptLoaderService.loadScript(true);
    }
  }

  ngOnInit(): void {
    dayjs.locale('it');
    dayjs.extend(customParseFormat);
    dayjs.extend(duration);

    this.isLanding = window.location.pathname.toLowerCase().includes("/landing");
    this.isQrPage = window.location.pathname.toLowerCase().includes("/qr");
    this.isStoreLocatorWidget$ = this._postMessageService.isStoreLocatorWidget();
    this.isStoreLocatorWidgetSubscription = this.isStoreLocatorWidget$.subscribe((isStoreLocatorWidget) => {
      this.isStoreLocatorWidget = isStoreLocatorWidget;
      if (!this.isLanding) {
        this._projectService.parentUrl = window.location.origin;

        this.isWidget$ = this._widgetService.isWidget();
        this.isWidgetSubscription = this.isWidget$.subscribe(isWidget => {
          this._projectService.isWidget = isWidget;
          if (isWidget) {
            this._postMessageService.getOrigin().toPromise().then(
                (resolved) => {
                  this._projectService.parentUrl = resolved;
                },
                (rejected) => {
                  console.error(rejected)
                },
            )
            document.body.classList.add('is-widget');
            setTimeout(() => {
              ocm?.autoAccept();
            }, 1000);
          } else if (!isWidget && !this.isStoreLocatorWidget) {
            this._cmsService.initialise();
            this._metaService.addMeta('google-site-verification', 'UUj6BrHk5c8V_OImdf8Z-C2qkn1WgG4gDWYD_E8n9Mw');
           /*  this.customJsHtmlSubscription = this._websiteConfigService.getCustomJsHtml().subscribe(e => {
              if (e) {
                // document.body.append(stringToElement(`<div id="customjshtml">${e}</div>`));
                // document.querySelectorAll('#customjshtml > script').forEach(element => {
                //   eval(element.innerHTML);
                //   //console.log(element.innerHTML);
                // });

                // REFACTORED ABOVE TO CODE BELOW

                const customScriptString = e.trim();
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = customScriptString;

                tempDiv.querySelectorAll('*').forEach(element => {
                  console.log("🚀 ~ AppComponent ~ tempDiv.querySelectorAll ~ element:", element)
                  element.classList.add('custom');
                  document.body.append(element);

                  if (element.nodeName == 'SCRIPT') {
                    eval(element.innerHTML);
                  }
                });
              }
            }); */
            this.customJsHtmlSubscription = this._websiteConfigService.getCustomJsHtml().subscribe(e => {
            if (e) {
              const customScriptString = e.trim();
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = customScriptString;

              let headContent = '';
              let bodyContent = '';

              tempDiv.querySelectorAll('*').forEach(element => {
                element.classList.add('custom');
                if (element.nodeName === 'SCRIPT') {
                  headContent += element.outerHTML;
                } else if (element.nodeName === 'NOSCRIPT') {
                  bodyContent += element.outerHTML;
                }
              });
              if (headContent) {
                if(!this.isPrerender){
                  this.processElements(headContent, 'script', 'custom-head', document.head);
                }
              }

              if (bodyContent) {
                this.processElements(bodyContent, 'noscript', 'custom-body', document.body);
              }
            }
          });
            this.fbPixelSubscription = this._websiteConfigService.getFbPixel().subscribe(fbPixelScript => {
              if (fbPixelScript) {
                document.body.append(stringToElement(`<div id="fbpixeljs">${fbPixelScript}</div>`));
                document.querySelectorAll('#fbpixeljs > script').forEach(element => {
                  eval(element.innerHTML);
                });
              }
            })
            setTimeout(() => {
              ocm?.init();
            }, 1000);
          }
        });

        if (this.isStoreLocatorWidget) {
          this.forceWtgStyle();
        } else {
          this._projectService.getAgencyNetwork().toPromise().then(
              (resolve) => {
                this.isWTG = Number(resolve.id_network) && Number(resolve.id_network) === Networks.WTG;
                //this._projectService.network = Number(resolve.id_network) === Networks.WTG ? 'WTG' : 'GEO';
                const networkConfig = NetworkConfigs[Number(resolve.id_network)];
                this._projectService.network = networkConfig.network;
                this.loadPartnerStyle(networkConfig);

                if (this.isWTG) {
                  document.body.classList.add('is-wtg');
                  // imposto la favicon dedicata ai siti wtg
                  document.querySelector('#custom-favicon').setAttribute('href', 'favicon_wtg.ico');
                } else {
                  document.querySelector('#custom-favicon').setAttribute('href', 'favicon_geo.ico');
                }
              },
              (reject) => {
                console.error(reject);
              }
          );
          this._projectService.getProfile().toPromise().then(
              (resolved) => {
                if (resolved?.email_1?.trim()?.length === 0 && resolved?.email_2?.trim()?.length === 0) {
                  alert('Configurazione errata.');
                  window.stop();
                  throw new Error('Configurazione Errata del sito.');
                }
              }
          );
        }

        // Temporary HACK
        this.cmsEnabledSub = this._cmsService.hasCmsEnabled$.subscribe(hasCms => {
          if (hasCms != undefined) {
            setTimeout(()=> {
              this.isLoaded = true;
            }, 450)
          }
        })
      }
    });
  }


  private processElements(container, elementType, className, parent) {
    const element = document.createElement('div');
    element.innerHTML = container;
    element.querySelectorAll(elementType).forEach(originalElement => {
      const newElement = document.createElement(elementType);
      newElement.innerHTML = originalElement.innerHTML;
      newElement.classList.add(className);
      parent.appendChild(newElement);
    });
  }

  ngOnDestroy(): void {
    this.isStoreLocatorWidgetSubscription?.unsubscribe();
    this.customJsHtmlSubscription?.unsubscribe();
    this.fbPixelSubscription?.unsubscribe();
    this.isWidgetSubscription?.unsubscribe();
    this.cmsEnabledSub.unsubscribe();
    this.toggleMenuNavSub.unsubscribe();
  }

  loadPartnerStyle(networkConfig: any): void {
    this._cmsService.brandConfig$.next(networkConfig);
    let bodyEl = this.document.getElementById("body-el");
    bodyEl.classList.add(networkConfig.bodyClass);

    this.document.getElementById('partner-style').setAttribute('href', networkConfig.cssFile);
  }

  forceWtgStyle(): void {
    const networkConfig = NetworkConfigs[16];
    this._projectService.network = "WTG";
    this._cmsService.brandConfig$.next(networkConfig);
    let bodyEl = this.document.getElementById("body-el");
    bodyEl.classList.add(networkConfig.bodyClass);
  }

}
